import { createEvent } from "effector";
import { $chosenEmpImgModal } from "./stores";

export const changeUserListPlaceIdEv = createEvent();

export const changeChosenUserEv = createEvent();

export const fireEmployeeEv = createEvent();

export const changeShowCreateModalEv = createEvent();

export const changeChosenPotentialEmpEv = createEvent();

export const changeChosenEmpImgModalEv = createEvent();

export const createEduUserEv = createEvent();

export const changeUserEmpCategoryEv = createEvent();

export const changeImportFileModalEv = createEvent();

export const sendImportFilesEv = createEvent();

export const getEduTemplateEv = createEvent();

export const changeChosenPotentialEmpEduEv = createEvent();

export const changeChosenEmpEduImgModalEv = createEvent();
