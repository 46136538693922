import React, { useEffect, useMemo, useState } from "react";
import { Button, Popconfirm, Segmented, Table } from "antd";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import {
  CloseCircleOutlined,
  EditOutlined,
  UploadOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import style from "./SchoolUserList.module.css";
import PotentialEmp from "./PotentialEmp";
import UserListModal from "./UserListModal";
import CreateUserModal from "./CreateUserModal";
import MakeEmpModal from "./MakeEmpModal";
import PotentialImagesModal from "./PotentialEmpImagesModal";
import {
  $userEmpCategory,
  $userList,
  $userListEdu,
  $userListPlaceId,
  changeChosenUserEv,
  changeImportFileModalEv,
  changeShowCreateModalEv,
  changeUserEmpCategoryEv,
  changeUserListPlaceIdEv,
  fireEmployeeEv,
  getUserListEduFx,
  getUserListFx,
} from "../../models/userListModel";
import { useUnit } from "effector-react";
import { $userGroups } from "../../models/userModel";
import { useNavigate } from "react-router-dom";
import { edu_field_names } from "../../dictionaries/edu-dictionary";
import ImportFilesModal from "./ImportFilesModal";

const empCategoryOptions = [
  {
    label: "Сотрудник",
    value: "Сотрудник",
  },
  {
    label: "Ученик",
    value: "Ученик",
  },
];

const cliendIdOptions = [
  {
    value: "klba36t1h2",
    label: "Kolibrix Спартака, 26",
  },
  {
    value: "klba36x2c2",
    label: "Kolibrix Жунисова, 2/13 к1",
  },
  {
    value: "amaa25d7c9",
    label: "Amanat Достык, 232/1",
  },
  {
    value: "crtz05x7d2",
    label: "Creatti, ул.Мангилик ел, д. 59",
  },
];

function SchoolUserList() {
  const userEmpCategory = useUnit($userEmpCategory);
  const changeUserEmpCategory = useUnit(changeUserEmpCategoryEv);
  const [tab, setTab] = useState(0);
  const userList = useUnit($userListEdu);
  const loading = useUnit(getUserListFx.pending);
  const userGroups = useUnit($userGroups);
  const navigate = useNavigate();
  const changeShowCreateModal = useUnit(changeShowCreateModalEv);
  const changeImportFileModal = useUnit(changeImportFileModalEv);

  const segmentedOptions = useMemo(
    () => [
      {
        label: "Сотрудник",
        value: "Сотрудник",
      },
      {
        label: "Ученик",
        value: "Ученик",
      },
    ],
    [userGroups],
  );

  const categoryOptions = [
    {
      label: "Все",
      value: 0,
    },
    {
      label: "Потенциальные сотрудники",
      value: 1,
    },
  ];

  useEffect(() => {
    // console.log('userGroups status: - ', !(userGroups.includes('Customer') && userGroups.includes('Amanat')))
    if (
      (Array.isArray(userGroups) &&
        userGroups.includes("Customer") &&
        userGroups.includes("Aubakirova")) ||
      (Array.isArray(userGroups) && userGroups.includes("portal_admin"))
    ) {
      console.log("got perms for User List");
    } else {
      console.log("redirected from User List");
      navigate("/dashboard");
    }
    // if (
    //   Array.isArray(userGroups) && !userGroups.includes('portal_admin') ||
    //   !(userGroups.includes('Customer') && userGroups.includes('Amanat'))) {
    // 	navigate('/dashboard')
    // }
  }, [userGroups, navigate]);

  useEffect(() => {
    getUserListEduFx();
  }, []);

  const columns = useMemo(() => {
    if (userEmpCategory === "Ученик") {
      return [
        // {
        //   title: "Действия",
        //   key: "user-list-actions",
        //   render: (text, record) => (
        //     <div className={style.table_actions_wrapper}>
        //       <Button
        //         icon={<EditOutlined style={{ fontSize: "14px" }} />}
        //         onClick={() => changeChosenUserEv(record)}
        //         style={{ width: "24px", height: "24px" }}
        //       />
        //       {record.emp_category === "Сотрудник" ? (
        //         <Popconfirm
        //           title={"Сотрудник уволен?"}
        //           okText={"Да"}
        //           cancelText={"Нет"}
        //           onConfirm={() => fireEmployeeEv(record)}
        //         >
        //           <Button
        //             icon={<CloseCircleOutlined style={{ fontSize: "14px" }} />}
        //             style={{ width: "24px", height: "24px" }}
        //           />
        //         </Popconfirm>
        //       ) : (
        //         ""
        //       )}
        //     </div>
        //   ),
        // },
        {
          title: edu_field_names.user_mobile,
          dataIndex: "user_mobile",
          key: "user_mobile",
        },
        {
          title: edu_field_names.created_at,
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: edu_field_names.grade,
          dataIndex: "grade",
          key: "grade",
        },
        {
          title: edu_field_names.user_gender,
          dataIndex: "user_gender",
          key: "user_gender",
        },
        {
          title: edu_field_names.user_email,
          dataIndex: "user_email",
          key: "user_email",
        },
        {
          title: edu_field_names.user_birth_day,
          dataIndex: "user_birth_day",
          key: "user_birth_day",
        },
        {
          title: edu_field_names.user_surname,
          dataIndex: "user_surname",
          key: "user_surname",
        },
        {
          title: edu_field_names.user_birth_year,
          dataIndex: "user_birth_year",
          key: "user_birth_year",
        },
        {
          title: edu_field_names.user_address,
          dataIndex: "user_address",
          key: "user_address",
        },
        {
          title: edu_field_names.work_type,
          dataIndex: "work_type",
          key: "work_type",
        },
        {
          title: edu_field_names.alarm,
          dataIndex: "alarm",
          key: "alarm",
        },
        {
          title: edu_field_names.emp_position,
          dataIndex: "emp_position",
          key: "emp_position",
        },
        {
          title: edu_field_names.emp_category,
          dataIndex: "emp_category",
          key: "emp_category",
        },
        {
          title: edu_field_names.shift,
          dataIndex: "shift",
          key: "shift",
        },
        {
          title: edu_field_names.user_name,
          dataIndex: "user_name",
          key: "user_name",
        },
        {
          title: edu_field_names.user_birth_month,
          dataIndex: "user_birth_month",
          key: "user_birth_month",
        },
        {
          title: edu_field_names.contacts,
          dataIndex: "contacts",
          key: "contacts",
          render: (contacts) => {
            return (
              contacts &&
              contacts.map((contact, index) => (
                <div key={`contact-${index}-${contact.name}`}>
                  <div>{`contact_mobile: ${contact.contact_mobile}`}</div>
                  <div>{`contact_name: ${contact.contact_name}`}</div>
                  <div>{`contact_surname: ${contact.contact_surname}`}</div>
                  <div>{`contact_type: ${contact.contact_type}`}</div>
                  <div>{`contact_email: ${contact.contact_email}`}</div>
                </div>
              ))
            );
          },
        },
      ];
    } else {
      return [
        // {
        //   title: "Действия",
        //   key: "user-list-actions",
        //   render: (text, record) => (
        //     <div className={style.table_actions_wrapper}>
        //       <Button
        //         icon={<EditOutlined style={{ fontSize: "14px" }} />}
        //         onClick={() => changeChosenUserEv(record)}
        //         style={{ width: "24px", height: "24px" }}
        //       />
        //       {record.emp_category === "Сотрудник" ? (
        //         <Popconfirm
        //           title={"Сотрудник уволен?"}
        //           okText={"Да"}
        //           cancelText={"Нет"}
        //           onConfirm={() => fireEmployeeEv(record)}
        //         >
        //           <Button
        //             icon={<CloseCircleOutlined style={{ fontSize: "14px" }} />}
        //             style={{ width: "24px", height: "24px" }}
        //           />
        //         </Popconfirm>
        //       ) : (
        //         ""
        //       )}
        //     </div>
        //   ),
        // },
        {
          title: edu_field_names.emp_department,
          dataIndex: "emp_department",
          key: "emp_department",
        },
        {
          title: edu_field_names.user_mobile,
          dataIndex: "user_mobile",
          key: "user_mobile",
        },
        {
          title: edu_field_names.created_at,
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: edu_field_names.user_gender,
          dataIndex: "user_gender",
          key: "user_gender",
        },
        {
          title: edu_field_names.user_email,
          dataIndex: "user_email",
          key: "user_email",
        },
        {
          title: edu_field_names.user_birth_day,
          dataIndex: "user_birth_day",
          key: "user_birth_day",
        },
        {
          title: edu_field_names.user_surname,
          dataIndex: "user_surname",
          key: "user_surname",
        },
        {
          title: edu_field_names.user_birth_year,
          dataIndex: "user_birth_year",
          key: "user_birth_year",
        },
        {
          title: edu_field_names.user_address,
          dataIndex: "user_address",
          key: "user_address",
        },
        {
          title: edu_field_names.emp_date,
          dataIndex: "emp_date",
          key: "emp_date",
        },
        {
          title: edu_field_names.work_type,
          dataIndex: "work_type",
          key: "work_type",
        },
        {
          title: edu_field_names.alarm,
          dataIndex: "alarm",
          key: "alarm",
        },
        {
          title: edu_field_names.dis_date,
          dataIndex: "dis_date",
          key: "dis_date",
        },
        {
          title: edu_field_names.emp_position,
          dataIndex: "emp_position",
          key: "emp_position",
        },
        {
          title: edu_field_names.emp_category,
          dataIndex: "emp_category",
          key: "emp_category",
        },
        {
          title: edu_field_names.user_name,
          dataIndex: "user_name",
          key: "user_name",
        },
        {
          title: edu_field_names.user_birth_month,
          dataIndex: "user_birth_month",
          key: "user_birth_month",
        },
        {
          title: edu_field_names.start_work_time,
          dataIndex: "start_work_time",
          key: "start_work_time",
          render: (start_work_time) => {
            return (
              <div>
                {start_work_time &&
                  Object.keys(start_work_time).map((item) => {
                    return (
                      <div
                        key={`start_work_time-${item}`}
                      >{`${item}: ${start_work_time[item]}`}</div>
                    );
                  })}
              </div>
            );
          },
        },
        {
          title: edu_field_names.end_work_time,
          dataIndex: "end_work_time",
          key: "end_work_time",
          render: (end_work_time) => {
            return (
              <div>
                {end_work_time &&
                  Object.keys(end_work_time).map((item) => {
                    return (
                      <div
                        key={`end_work_time-${item}`}
                      >{`${item}: ${end_work_time[item]}`}</div>
                    );
                  })}
              </div>
            );
          },
        },
        {
          title: edu_field_names.subject,
          dataIndex: "subject",
          key: "subject",
          render: (subject) => subject?.join(", "),
        },
      ];
    }
  }, [userEmpCategory]);

  return (
    <div className={style.userList_wrapper}>
      <div className={style.userList_title}>Список сотрудников</div>
      <Segmented options={categoryOptions} value={tab} onChange={setTab} />
      {tab === 0 ? (
        <>
          <div className={style.user_list_top_wrapper}>
            <Segmented
              options={segmentedOptions}
              value={userEmpCategory}
              onChange={changeUserEmpCategory}
            />
            <div className={style.top_buttons_wrapper}>
              {/*{userGroups?.includes("portal_admin") ? (*/}
              {/*  <CustomSelect*/}
              {/*    options={empCategoryOptions}*/}
              {/*    onSelect={changeUserEmpCategory}*/}
              {/*    field={"emp_category"}*/}
              {/*    placeholder="Emp category"*/}
              {/*    value={userEmpCategory}*/}
              {/*    searchIcon={true}*/}
              {/*  />*/}
              {/*) : (*/}
              {/*  ""*/}
              {/*)}*/}
              <Button
                className={style.add_user_button}
                icon={<UploadOutlined />}
                onClick={changeImportFileModal}
                type={"primary"}
              >
                Импорт данных
              </Button>
              <Button
                className={style.add_user_button}
                icon={<UserAddOutlined />}
                onClick={changeShowCreateModal}
                type={"primary"}
              >
                Добавить сотрудника
              </Button>
            </div>
          </div>
          <Table
            loading={loading}
            className={style.userList_table}
            dataSource={userList}
            columns={columns}
            scroll={{
              x: "max-content",
            }}
            bordered
            pagination={{
              locale: { items_per_page: "стр." },
              // itemRender: (page, type) => {
              // 	if (type === 'prev') {
              // 		return <div style={{border: '1px solid #D9D9D9'}}><LeftOutlined /></div>
              // 	}
              // 	if (type === 'next') {
              // 		return <div style={{border: '1px solid #D9D9D9'}}><RightOutlined /></div>
              // 	}
              // 	if(type === 'page') {
              // 		return <div style={{border: '1px solid #D9D9D9'}}>{page}</div>
              // 	}
              // 	if(type.includes('jump')) {
              // 		return <EllipsisOutlined />
              // 	}
              // }
            }}
          />
        </>
      ) : (
        <PotentialEmp />
      )}
      <UserListModal />
      <CreateUserModal />
      <MakeEmpModal />
      <PotentialImagesModal />
      <ImportFilesModal />
    </div>
  );
}

export default SchoolUserList;
